import React from 'react'

function Footer() {
    return (
        <>
            <div className='footer'>
                <div className="footercol1">
                    <a href='/'> <img src="./assets/yplogo.png" className='logoImg' /></a>
                </div>
                <div className="footercol2">
                    <ul className='footerMenu'>
                        <li>Some Link</li>
                        <li>Some Link</li>
                        <li>Some Link</li>
                    </ul>
                </div>
                <div className="footercol3">
                    <ul className='footerMenu'>
                        <li>Some Link</li>
                        <li>Some Link</li>
                        <li>Some Link</li>
                    </ul>
                </div>

            </div>
        </>

    )
}

export default Footer