import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { updateWallet } from '../app/connectWeb3'
import info from '../info.json'
import Loading from './Loading';
import Web3 from 'web3';
import { toast } from "react-toastify";
import { presaleAbi } from '../abi/presaleAbi';



function Card() {
    const { pStats, pspending, pserror } = useSelector((state) => state.presale)
    const { web3g, pending, error } = useSelector((state) => state.wallet)
    const tokenSymbol = info.tokenSymbol
    const dispatch = useDispatch();

    const [bnbAmount, setBnbAmount] = useState("")
    const [tokenAmount, setTokenAmount] = useState("")
    const [rate, setRate] = useState("")
    const [referralAddress, setReferralAddress] = useState("")
    useEffect(() => {
        setRate(pStats.rate);
    }, [pStats])
    const handleChange = async (e) => {
        if (e.target.value == "") {
            setBnbAmount("")
            setTokenAmount("")
        }
        if (e.target.name == "bnb") {
            const amount = e.target.value;
            setBnbAmount(amount)
            setTokenAmount(() => amount * rate)
        } else {
            const amount = e.target.value;
            setTokenAmount(amount)
            setBnbAmount(() => amount / rate)
        }
    }
    const getRef = () => {
        const web3 = new Web3(info.readOnlyApi);
        const url = new URL(window.location.href)
        if (localStorage.hasOwnProperty('ref')) {
            const ref = localStorage.getItem('ref')
            if (web3.utils.isAddress(ref)) {
                setReferralAddress(ref)
            } else {
                setReferralAddress(info.refWallet)
            }
        }
        else if (url.searchParams.get('ref')) {
            const ref = url.searchParams.get('ref');
            if (web3.utils.isAddress(String(ref))) {
                localStorage.setItem('ref', ref)
                setReferralAddress(ref)
            } else {
                setReferralAddress(info.refWallet)
            }

        } else {
            setReferralAddress(info.refWallet)
        }
        /* try {
 
             if (ref) {
                 const isRefValid = web3g.utils.isAddress(ref)
                 setReferralAddress(ref)
                 console.log(ref)
                 console.log(isRefValid)
             }
         } catch (error) {
             console.log('noref')
         }*/
    }

    useEffect(() => {
        getRef()

    }, [])

    const validateBuy = () => {
        if (bnbAmount < info.minBuy || bnbAmount > info.maxBuy) {
            toast.error("You can buy minimum " + info.minBuy + "BNB and maximum " + info.maxBuy + "BNB", {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            buyTokens();
        }
    }

    const CustomToastWithLink = (hash) => (
        <div>
            <a href={info.blockExplorer + "tx/" + hash} target='_blank'>Transaction send click to see on explorer</a>
        </div>
    );



    const checkNetworkAndAmounts = () => {
        if (web3g.rightChain) {
            validateBuy()
        } else {
            toast.error("You are on wrong network", {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }

    }


    function waitForReceipt(hash, cb) {
        web3g.web3.eth.getTransactionReceipt(hash, function (err, receipt) {
            if (err) {
                console.log(err);
            }

            if (receipt !== null) {
                // Transaction went through
                if (cb) {
                    cb(receipt);
                }
            } else {
                // Try again in 1 second
                window.setTimeout(function () {
                    waitForReceipt(hash, cb);
                }, 1000);
            }
        });
    }

    /*
    , function (error, transactionHash) {
                   
    
    
    */

    const buyTokens = async () => {
        const contract = new web3g.web3.eth.Contract(presaleAbi, info.contractAddress)
        const value = web3g.web3.utils.toWei(bnbAmount)
        try {
            await contract.methods.buyTokens(web3g.walletAddress, referralAddress).send({ from: web3g.walletAddress, value: value, gasLimit: '350000' }, function (error, transactionHash) {
                if (!error) {
                    toast.info(CustomToastWithLink(transactionHash))
                    waitForReceipt(transactionHash, function (receipt) {
                        if (receipt.status) {
                            toast.success("Transaction confirmed", {
                                position: "top-right",
                                autoClose: 3500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                            updateWallet({}, dispatch)
                        } else {
                            toast.error("Transaction not confirmed!!", {
                                position: "top-right",
                                autoClose: 3500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    })
                }
            })

        } catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    return (
        <>
            <div className='cardwrap'>
                <div className="card card-text">
                    <div className="columnFlex">
                        <div className="wbg">
                            <input type="text" className='currencyInput mt-3 mb-2' name="bnb" placeholder={info.chainCurrency + ' Amount...'} value={bnbAmount} onChange={handleChange} disabled={!web3g.rightChain || pspending} autoComplete="off" />
                            <input type="text" className='currencyInput mt-2 mb-3' name="token" placeholder={info.tokenSymbol + ' Amount...'} value={tokenAmount} onChange={handleChange} disabled={!web3g.rightChain || pspending} autoComplete="off" />
                        </div>
                    </div>

                </div>

            </div>
            <div className="buttonWrap">
                <div className="buyButton" onClick={() => web3g.connected ? checkNetworkAndAmounts() : updateWallet({}, dispatch)}><h5>{pending ? <Loading /> : web3g.connected ? (web3g.rightChain ? "Buy " + tokenSymbol : "Wrong Network") : "Connect Wallet"}</h5></div>

            </div>

        </>
    )
}

export default Card