import { createSlice } from "@reduxjs/toolkit";

export const presaleSlice = createSlice({
    name: "presale",
    initialState: {
        pStats: {
            weiRaised: "",
            rate: "",
            refpercent: "",
            tokenAddress: "",
            refBool: false,
        },
        pspending: false,
        pserror: false
    },
    reducers: {
        psconnectionStart: (state) => {
            state.pspending = true;
        },
        psconnectionSuccess: (state, action) => {
            state.pspending = false;
            state.pStats = action.payload;
        },
        psconnectionError: (state) => {
            state.pspending = false;
            state.pserror = true;
        }
    }
})

export const { psconnectionStart, psconnectionSuccess, psconnectionError } = presaleSlice.actions;
export default presaleSlice.reducer;