import { configureStore } from '@reduxjs/toolkit';
import walletReducer from '../features/web3/walletSlice.js';
import presaleReducer from '../features/web3/presaleSlice.js'

export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    presale: presaleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
