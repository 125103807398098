import React, { useState, useEffect } from 'react'
import info from '../info.json'
import { useSelector } from 'react-redux'
import Web3 from 'web3'

function Progress() {
    const { pStats, pspending, pserror } = useSelector((state) => state.presale)

    const [bnbCollected, setBnbCollected] = useState("");
    const [barwidth, setBarwidth] = useState("");

    useEffect(() => {
        setBnbCollected(pStats.weiRaised);
        const hardcap = info.hardCap;
        const web3 = new Web3(info.readOnlyApi)
        const collectedFromWei = web3.utils.fromWei(pStats.weiRaised)
        const result = (collectedFromWei / hardcap) * 100
        setBarwidth(Number(result))

    }, [pStats])



    return (
        <>
            <div className="ptextWrap">
                <h3 className='pt-1'>Welcome to {info.tokenName} Presale</h3>
                <h5 className='pt-1'>Hardcap : {info.hardCap + " " + info.chainCurrency}</h5>
            </div>

            <div className="pwrap">
                <div className="progress">
                    <div className="bar shadow bars" style={{ width: barwidth + "%" }}></div>
                </div>
            </div>
            <div className="text-center">
                <h5>Rate : 1 {info.chainCurrency + " = " + pStats.rate + " " + info.tokenSymbol}</h5>
            </div>
        </>


    )
}

export default Progress