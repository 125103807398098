import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { updateWallet } from './app/connectWeb3'
import { updatePresale } from './app/connectPresale'
import Card from './components/Card';
import Footer from './components/Footer';
import Header from './components/Header';
import Progress from './components/Progress';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Referral from './components/Referral';
import Bgpatterns from './components/Bgpatterns';



function App() {

  const dispatch = useDispatch();
  const { web3g, pending, error } = useSelector((state) => state.wallet)
  const { pStats, pspending, pserror } = useSelector((state) => state.presale)




  useEffect(() => {
    if (web3g.connected) {
      async function getAddress() {

        web3g.provider.on("accountsChanged", (accounts) => {
          updateWallet({}, dispatch)
        });
        web3g.provider.on('chainChanged', (_chainId) => { updateWallet({}, dispatch); console.log("chain") });
      }
      getAddress()

    }
  }, [web3g])




  useEffect(() => {
    if (!pspending) {
      updatePresale({}, dispatch);
    }
  }, [])




  return (
    <>
      <Bgpatterns />
      <ToastContainer />
      <Header />
      <Progress />
      <Card />
      {pStats.refBool ? <Referral /> : <div style={{ height: 20 + 'vh' }}></div>}
      <Footer />

      {/* Same as */}

    </>

  );
}

export default App;
