import React from 'react'

function Bgpatterns() {
    return (
        <>
            <div className="backBorderPattern animateBaloons" ></div>
            <div className="backBorderPattern1 animateBaloons" ></div>
            <div className="backBorderPattern2 animateBaloons"></div>
            <div className="backBorderPattern3 animateBaloons"></div>
            <div className="backBorderPattern4 animateBaloons"></div>
            <div className="backBorderPattern5 animateBaloons"></div>
        </>
    )
}

export default Bgpatterns